require("./scss/home.scss");

var btns = document.getElementsByClassName("btns");

// Array.from(btns).forEach(function(btn) {
//     btn.addEventListener('click', function() {
//      console.log(this.value);
//        var textArea = document.getElementById("textarea");
//       var newValue= this.value;
//        console.log(newValue)
//     textArea.innerHTML = "Ola gostaria de uma consulta com " + newValue;
//     });
//   });

// function showBtns() {
    var btn = [...btns];
    btn.forEach(btn => {
        btn.addEventListener("click", function(e){
            // console.log(btn.value)
            const newValue = btn.value;
            // console.log(newValue)
            const textArea = document.getElementById("textarea");
            textArea.innerHTML = "Olá gostaria de uma consulta com " + newValue;
        })
    });
// }

window.onload = btn;









